
import appConfig from 'app-config-global'

type EnvReturn = 'dev' | 'staging' | 'production'

export const getEnv = (): EnvReturn => {
  switch (appConfig.stage) {
    case 'develop':
      return 'dev'
    case 'release':
      return 'staging'
    case 'master':
      return 'production'
  }
  return 'production'
}
