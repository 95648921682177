
import { BaseApiController, AuthProvider, AppConfigData } from '@mit/aws-react'
import appConfig from 'app-config-global'
import apiConfig from 'Api/api-config'

// Override any methods from TemplateConstant BaseController here. Extend all other controllers off this controller
export class BaseController extends BaseApiController {
  public readonly appConfig: AppConfigData
  public readonly apiAppConfig: any
  public readonly apiHost: string
  public readonly apiPathDigitalId: string

  readonly apiPathAccount: string

  constructor () {
    super()

    this.appConfig = (appConfig as unknown) as AppConfigData

    const isGlobalEnabled = localStorage.getItem('isGlobalEnabled') === 'true' ?? false
    const configKey = (isGlobalEnabled ? 'global' : 'regional')

    this.apiHost = apiConfig[configKey].apiHost ?? ''
    this.apiPathDigitalId = apiConfig[configKey].paths.digitalId ?? ''
    this.apiPathAccount = apiConfig[configKey].paths.account
  }

  public async getAuthorizationHeaders (): Promise<Record<string, string>> {
    const token = await new AuthProvider(this.appConfig).getToken()

    return {
      Authorization: `Bearer ${token}`
    }
  }
}
