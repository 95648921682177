const apiConfigs = {
  "regional": {
    "apiHost": "https://api.mit.edu",
    "paths": {
      "account": "account-v2",
      "digitalId": "digital-id-v1"
    }
  },
  "global": {
    "apiHost": "https://global.api.mit.edu",
    "paths": {
      "account": "account/v2",
      "digitalId": "digital-id/v1"
    }
  }
};
export default apiConfigs;